import React from "react";
import GridContainer from "components/Grid/GridContainer.js";

export default function Dashboard() {
  return (
    <div>
      <GridContainer>
        <h1>Dashboard</h1>
      </GridContainer>
    </div>
  );
}
