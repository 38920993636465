const ADD_CAMPAIGN_TEMPLATE = "ADD_CAMPAIGN_TEMPLATE";
const ADD_CAMPAIGN_TEMPLATE_SUCCESS = "ADD_CAMPAIGN_TEMPLATE_SUCCESS";
const ADD_CAMPAIGN_TEMPLATE_FAILURE = "ADD_CAMPAIGN_TEMPLATE_FAILURE";

const GET_TEMPLATES = "GET_TEMPLATES";
const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

const TURN_TO_NULL = "TURN_TO_NULL";
const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

export default {
  ADD_CAMPAIGN_TEMPLATE,
  ADD_CAMPAIGN_TEMPLATE_SUCCESS,
  ADD_CAMPAIGN_TEMPLATE_FAILURE,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILURE,
  TURN_TO_NULL,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,

  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
};
