const ADD_CAMPAIGN_INDUSTRY = "ADD_CAMPAIGN_INDUSTRY";
const ADD_CAMPAIGN_INDUSTRY_SUCCESS = "ADD_CAMPAIGN_INDUSTRY_SUCCESS";
const ADD_CAMPAIGN_INDUSTRY_FAILURE = "ADD_CAMPAIGN_INDUSTRY_FAILURE";

const GET_INDUSTRIES = "GET_INDUSTRIES";
const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";
const GET_INDUSTRIES_FAILURE = "GET_INDUSTRIES_FAILURE";

const TURN_TO_NULL = "TURN_TO_NULL";
const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
const UPDATE_INDUSTRY_SUCCESS = "UPDATE_INDUSTRY_SUCCESS";
const UPDATE_INDUSTRY_FAILURE = "UPDATE_INDUSTRY_FAILURE";

const DELETE_INDUSTRY_REQUEST = "DELETE_INDUSTRY_REQUEST";
const DELETE_INDUSTRY_SUCCESS = "DELETE_INDUSTRY_SUCCESS";
const DELETE_INDUSTRY_FAILURE = "DELETE_INDUSTRY_FAILURE";

export default {
  ADD_CAMPAIGN_INDUSTRY,
  ADD_CAMPAIGN_INDUSTRY_SUCCESS,
  ADD_CAMPAIGN_INDUSTRY_FAILURE,
  GET_INDUSTRIES,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAILURE,
  TURN_TO_NULL,
  UPDATE_INDUSTRY,
  UPDATE_INDUSTRY_SUCCESS,
  UPDATE_INDUSTRY_FAILURE,

  DELETE_INDUSTRY_REQUEST,
  DELETE_INDUSTRY_SUCCESS,
  DELETE_INDUSTRY_FAILURE,
};
