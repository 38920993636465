export const LOGIN = "LOGIN";
export const LOGEDIN = "LOGGEDIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

export const LOAD_USERS = "LOAD_USERS";
export const USERS_LOADED = "USERS_LOADED";
export const USERS_LOADED_FAILED = "USERS_LOADED_FAILED";

export const LOAD_PUBLIC_ASSETS = "LOAD_PUBLIC_ASSETS";
export const PUBLIC_ASSETS_LOADED = "PUBLIC_ASSETS_LOADED";
export const LOADING_PUBLIC_ASSETS_FAILED = "LOADING_PUBLIC_ASSETS_FAILED";

export const ADD_NEW_PUBLIC_ASSET = "ADD_NEW_PUBLIC_ASSET";
export const NEW_PUBLIC_ASSET_ADDED = "NEW_PUBLIC_ASSET_ADDED";
export const ADDING_NEW_PUBLIC_ASSET_FAILED = "ADDING_NEW_PUBLIC_ASSET_FAILED"

export const GET_PUBLIC_MUSIC = "GET_PUBLIC_MUSIC";
export const GET_PUBLIC_MUSIC_SUCCESS= "GET_PUBLIC_MUSIC_SUCCESS";
export const GET_PUBLIC_MUSIC_FAILURE = "GET_PUBLIC_MUSICE_FAILURE";

export const USER_REMOVE = "USER_REMOVE";
export const USER_REMOVE_S = "USER_REMOVE_S";